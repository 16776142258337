<template>
  <div
    :class="['line-pic-item', { 'disable': selectDisable }]"
    @click="handleSkipDetail"
  >
    <!-- 选择器 -->
    <div
      v-if="showSelect"
      class="line-pic-item__select"
    >
      <s-checkbox
        v-model="checkedStatus"
        margin-r="0"
        @change="handleSelect"
      />
    </div>
  
    <!-- 内容 -->
    <SwipeCell
      :class="[{ 'line-pic-wrap__first': index === 0 }, 'line-pic-wrap']"
      :direction="constantData.GB_cssRight ? 'right' : 'left'"
      :disabled="showSelect"
      @slideDone="itemSlideDone"
    >
      <div
        ref="lineItem"
        v-bind="pointsConfig"
        class="line-pic-wrap__container product-item-ctn"
        @click="handleSelect"
      >
        <!-- 图片 -->
        <LeftImg 
          :constant-data="constantData"
          :img-url="item.goods_img"
          :video-url="item.video_url"
          :pretreat-info="pretreatInfo"
          :config="config"
          :language="language"
          :promo-label-src="promoLabelSrc"
          :lcp="lcp"
          :locate-labels="locateLabels"
          @longClick="longClick"
        />

        <!-- 内容 -->
        <RightContent
          :constant-data="constantData"
          :item="item"
          :index="index"
          :language="language"
          :config="config"
          :pretreat-info="pretreatInfo"
          :sale-attrs="saleAttrs"
          :show-select="showSelect"
          :show-collect-num="showCollectNum"
          @handleShowFindSimilar="handleShowFindSimilar"
          @handleQuickAdd="handleQuickAdd"
          @handleDelete="handleDelete"
          @handleBottomMove="handleBottomMove"
          @exposeSellingPoint="exposeSellingPoint"
          @showCccTspBadge="exposeShowCccTspBadge"
          @showUserAct="exposeShowUserAct"
          @showQuickShip="showQuickShip"
        />
      </div>

      <template slot="right">
        <div
          class="line-pic-wrap__deleteBtn"
          style="width:64px;"
          @click.stop="handleDelete('left')"
        >
          {{ language.SHEIN_KEY_PWA_15198 }}
        </div>
      </template>
    </SwipeCell>

    <!-- 遮罩层  -->
    <ShadeOperation
      v-model="showShadeOperation"
      :show-add-board="config.showAddToBoardBtn"
      :is-out-of-stock="isOutOfStock"
      :constant-data="constantData"
      :language="language"
      @handleDelete="handleDelete"
      @addBoard="$emit('clickAddToBoard', item)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import SwipeCell from 'public/src/pages/components/product/swipeCell/SwipeCell.vue'
import { Checkbox, Button } from '@shein/sui-mobile'
import { stringifyQueryString } from '@shein/common-function'
import { getLocateLabelsAna } from 'public/src/pages/components/product/item_v2/analysis/locateLabelsAna'
import LeftImg from './components/LeftImg.vue'
import RightContent from './components/rightContent/index.vue'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'

Vue.use(Checkbox)
Vue.use(Button)

import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import analysis from './analysis'

export default {
  name: 'ItemVue',
  components: {
    ShadeOperation: () => import(/* webpackChunkName: "one_line_item" */ './components/ShadeOperation.vue'),
    SwipeCell,
    LeftImg,
    RightContent,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: () => -1
    },
    // 进入选择状态
    showSelect: {
      type: Boolean,
      default: () => false
    },
    // 选择状态切换
    selectStatus: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    selectDisable: Boolean,
    constantData: {
      type: Object,
      default: () => ({}),
    },
    showCollectNum: Boolean, // 收藏数展示
    lcp: Boolean,
  },
  data () {
    return {
      showShadeOperation: false,   // 遮罩操作层
      slideDoneCount: 0,           // 滑动出删除按钮次数
      checkedStatus: false,
      originPoint: {}
    }
  },
  computed: {
    pretreatInfo() {
      return this.item?.pretreatInfo || {}
    },
    locateLabels () {
      return this.item?.mobileVerticalView?.locateLabels || {}
    },
    // 商品无库存
    isOutOfStock () {
      const attrValueId = this.item?.attrValueId
      const _stock = !attrValueId || +attrValueId === 0 ? +this.item?.stock : +this.item?.sizeStock
      return this.item?.is_on_sale == 0 || _stock == 0
    },
    // 降价标识价格
    reducePrice () {
      return this.item?.reducePrice?.amountWithSymbol
    },
    _showSpuImg () {
      const spuImg = (this.item?.spu_image || [])[0]?.medium_image
      return !!(this.config.showSpuImg && spuImg && spuImg !== 'filteredSpuImg')
    },
    promotionInfo () {
      let promotionInfo = this.pretreatInfo?.mixPromotionInfo
      return promotionInfo && promotionInfo.length > 0 && promotionInfo[0] || null
    },
    promotionLabel () {
      return this.config.showPromotion && this.promotionInfo?.lable || ''
    },
    // 是新型闪购（新人，专属）
    newFlashPromotion () {
      return this.item?.pretreatInfo?.newFlashPromotion || {}
    },
    // 埋点数据
    pointsConfig() {
      const locateLabelSet = new Set(Object.keys(this.locateLabels || {}).filter(key => Object.keys(this.locateLabels[key] || {}).length))
      const locateLabelsAna = getLocateLabelsAna(this.locateLabels, locateLabelSet)

      const resultConfig = Object.assign(this.originPoint, 
        { 
          'da-event-click': this.config.itemDAEventClickId || '2-3-1',
          'da-event-expose': this.config.itemDAEventExposeId || '2-3-2',
          'data-sku': this.item?.goods_sn || '',
          'data-spu': this.item?.productRelationID || '',
          'data-id': this.item?.goods_id || '',
          'data-name': this.item?.goods_name || '',
          'data-rec_mark': this.item?.ext?.rec_mark || '',
          'data-extra_mark': this.item?.ext?.extra_mark || '',
          'data-other_ext_mark': this.item?.ext?.other_ext_mark || '',
          'data-other_d_ext_mark': this.item?.dynamic_ext?.other_d_ext_mark || '',
          'data-cat_id': this.item?.cat_id || '',
          'data-price': this.item?.salePrice?.amount || '',
          'data-us-price': this.item?.salePrice?.usdAmount || '',
          'data-us-origin-price': this.item?.retailPrice?.usdAmount || '',
          'data-index': this.index,
          'data-type': this.item?.dataSource || '', // config.moduleFrom == 'recommend'
          'data-similar': this.isOutOfStock && this.config.showSimilarBtn || '',
          'data-reduce-price': !this.isOutOfStock && this.pretreatInfo?.reducePrice?.usdAmount || '',
          'data-soldout': Number(this.isOutOfStock), // data-soldout 目前只有在最近页面有用到
          'data-sold-out': Number(this.isOutOfStock), // data-sold-out 需要用到其他页面上，因为上报的标识不一样，需要另外设置一个属性
          'data-show-exclusive-price': this.item?.exclusivePromotionPrice || '',
          'data-lable': this.pretreatInfo?.label || '',
          'data-series-brand': this.pretreatInfo?.seriesOrBrandAnalysis || '',
          'data-brand': this.item?.brand_badge_new?.name || '',
          'data-video': this.item?.video_url ? 'video_icon' : '',
          'data-spu-img': (this.item?.spu_image || [])[0] && (this._showSpuImg ? 'spupic_1' : 'spupic_0') || '', // 有spu图且展示则上报spupic_1，有spu图但不展示则上报spupic_0，无spu图不上报
          'data-mall_tag_code': `${this.pretreatInfo?.mallTagsInfo?.mall_tags || ''}_${this.item?.mall_code || '-'}`,
          'data-store_code': this.item?.store_code ?? '',
          'data-sale-attr': this.pretreatInfo?.saleAttr?.analysis || '',
          'data-show-promot-info': this.promotionLabel || '',
          'data-price-cut': this.pretreatInfo?.priceCut || '',
          'data-promotion-id': this.newFlashPromotion?.id || this.pretreatInfo?.flashsale?.info?.id || '',
          'data-type-id': this.newFlashPromotion?.typeId || this.pretreatInfo?.flashsale?.info?.typeId || '',
          ...this.originPoint,
          'data-promo-label': this.promoLabelSrc ? 1 : '',
          'data-belt-label': this.pretreatInfo?.mobileBroadwiseView?.beltLabel?.ana || '',
          'data-locate-labels': locateLabelsAna || '',
          'data-ranking-list': this.pretreatInfo?.mobileBroadwiseView?.rankingList?.ana || '',
          'data-user-behavior-label': this.pretreatInfo?.mobileBroadwiseView?.userBehaviorLabel?.ana || '',
          'data-user-comment-keywords-label': this.pretreatInfo?.mobileBroadwiseView?.userCommentKeywordsLabel?.ana || ''
        })
      return resultConfig
    },
    // 销售属性
    saleAttrs() {
      if(!this.config?.showSaleAttr) return ''
      let valueNames = [];
      (this.item?.sku_attr_list || [] ).forEach(i => {
        i.is_main === 0 && (valueNames.push(i.attr_value_multi_name))
      })
      return '' + valueNames.join('/')
    },
    promoLabelSrc() {
      return this.config.showPromoLabel && this.item?.promoLabel?.src
    }
  },
  watch: {
    selectStatus: {
      handler(v) {
        if (v === this.checkedStatus) return
        this.checkedStatus = v
      },
      immediate: true
    },
  },
  mounted() {
    this.$emit('mounted', this)
  },
  methods: {
    /* 跳转商详 */ 
    handleSkipDetail(e) {
      if (this.showSelect) {  // 编辑状态不跳转
        e.stopPropagation() // 阻止冒泡触发埋点
        return 
      }
      const { goods_id, cat_id, mall_code: mallCode } = this.item
      const params = {
        mallCode,
        skucode: this.saleAttrs && this.item.sku_code || ''
      }
      _gb_app_.$store.commit('addProductDetailBfCache', this.item) // 埋点
      const url = `${this.constantData.langPath}${getGoodsUrl(
        `product`, 
        goods_id, 
        cat_id)}?${stringifyQueryString({ queryObj: params })}`
      
      this.$router.push(url)
    },

    // 找相识
    handleShowFindSimilar () {
      const data = {
        ...this.item,
        item_sold_out: Number(!!this.isOutOfStock),
        findsimilar_method: 'ItemsOnePic', // 一行一图
      }
      SimilarInstance.showModal(data, { similarFrom: this.isOutOfStock ? 'out_of_stock' : 'other' })

      analysis.findSimilarEventCenter({
        daEventId: this.config.itemDAEventClickId,
        data,
        activeFrom: this.config.sa?.activity_from
      })
    },

    // 加车
    handleQuickAdd() {
      if (this.isOutOfStock) return
      this.$emit('openQuickAdd', {
        index: this.index,
        target: this.$refs.lineItem,
        item: this.item
      })
      analysis.addCartEventCenter(this.$refs.lineItem)
    },

    // 选择商品
    handleSelect() {
      if (!this.showSelect) return
      this.$emit('select', { item: this.item, selected: !this.selectStatus })
    },

    // 长按
    longClick() {
      this.$emit('longClick', { item: this.item })
    },

    // 删除操作
    handleDelete(deleteType) {
      analysis.removeItemCenter({
        goods_id: this.item.goods_id,
        activity_from: this.config?.sa?.activity_from || '',
        item_sold_out: this.isOutOfStock ? 1 : 0,
        delete_method: deleteType === 'left' ? 'SwipeLeft' : 'ClickMore'
      })

      this.$emit('clickDelete', {
        ...this.item,
        sku: this.item.goods_sn,
        index: this.index,
        isOutOfStock: Number(this.isOutOfStock),
        deleteType,
      })
    },

    // 右下角更多
    handleBottomMove() {
      this.showShadeOperation = true
      this.$emit('clickMoreBtn', {
        index: this.index,
        item: {
          ...this.item,
          item_sold_out: Number(this.isOutOfStock),
        }
      })
    },

    itemSlideDone() {
      this.slideDoneCount++
      this.$emit('slideDone', this.item, this.slideDoneCount)
    },

    // point标签 曝光埋点
    exposeSellingPoint(sellingPointLabel = '') {
      this.originPoint = {
        ...this.originPoint,
        'data-selling-point': sellingPointLabel,
      }
    },

    exposeShowCccTspBadge(isShow) {
      const badges = []
      const imgUpperLeft =  this.pretreatInfo?.imgUpperLeft  // 图片左上角ccc-tsp角标
      const imgBottomLeft = this.pretreatInfo?.imgBottomLeft // 图片左下角ccc-tsp角标
      const goodsBottom = this.pretreatInfo?.goodsBottom    // 商品下方ccc-tsp角标
      if (imgUpperLeft?.code) badges.push(imgUpperLeft.code)
      if (imgBottomLeft?.code) badges.push(imgBottomLeft.code)
      if (goodsBottom?.code && isShow) badges.push(goodsBottom.code)

      this.originPoint = {
        ...this.originPoint,
        'data-badges': badges.join('|')
      }
    },

    exposeShowUserAct (text) {
      this.originPoint = {
        ...this.originPoint,
        'data-user-act': text
      }
    },

    showQuickShip() {
      this.originPoint = {
        ...this.originPoint,
        'data-quick-ship': 'quickship_1'
      }
    }
  },
}
</script>

<style lang='less'>
.line-pic-item {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;

  &.disable {
    opacity: .6;
  }

  // 选择圆圈
  &__select {
    padding-right: 0.32rem;
    .select-icon {
      position: relative;
      height: 0.53rem;
      width: 0.53rem;
      border: 1px solid #c5c5c5;
      border-radius: 50%;
      i {
        font-size: .65rem;
        color: @color_brand;
        &::before {
          position: absolute;
          top: -0.25rem;
          left: -0.05rem;
        }
        &.icon-checked {
          font-size: 20px;
          top: -.15rem;
        }
      }
      &__transparent {
        border: 1px solid transparent;
      }
    }
  }
}

.line-pic-wrap {
  padding: .43rem 0;
  width: 100%;

  &__first {
    padding-top: 0;
  }
  &__container {
    position: relative;
    width: 100%;
    display: flex;
    height: 3.41rem;
  }

  &__deleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 14px;
    height: 3.41rem;
    background: @sui_color_unusual;
    color: #fff;
    font-size: 14px;
  }
}

.line-pic-item+.line-pic-item {
  border-top: 1px solid #e5e5e5;
}
</style>
