<template>
  <div
    class="collection-counter"
  >
    <!-- <sui_icon_save_completed_20px
      color="#767676"
      size="12px"
    /> -->
    <i
      class="suiiconfont sui_icon_save_completed_20px"
      :style="{
        color: '#767676',
        'font-size': '12px',
      }"
    ></i>
    <span class="collection-num">{{ itemCollection }}</span>
  </div>
</template>

<script>
/**
 * @file 收藏人数-组件
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WishNum',
  props: {
    itemCollection: {
      type: [String, Number],
      default: 0,
    },
  },
})
</script>

<style lang="less" scoped>
.collection-counter {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    
    .collection-num {
        margin-left: 2px;
        color: #767676;
        font-size: 12px;
        font-weight: 400;
    }
}
</style>
