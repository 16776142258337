<template>
  <span
    class="stock-left-container"
  >
    {{ stockText }}
  </span>
</template>
  
<script>
/**
 * @file 低库存组件
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StockLeft',
  props: {
    stockText: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="less" scoped>
.stock-left-container {
    overflow: hidden;
    -webkit-line-clamp: 1;
    color: #767676;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    vertical-align: bottom;
    max-width: 2.5rem;
}
</style>
