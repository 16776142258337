import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-17' })
daEventCenter.addSubscriber({ modulecode: '1-10-3' })
daEventCenter.addSubscriber({ modulecode: '1-10-4' })
daEventCenter.addSubscriber({ modulecode: '1-10-5' })

// 曝光事件
const exposeEvent = {

}

// 点击事件
const clickEvent = {
  // 左滑显示删除，唤起次数
  slideDeleteShow({ count, goods_id }) {
    daEventCenter.triggerNotice({
      daId: '1-10-4-8',
      extraData: {
        goods_id,
        count,
      },
    })
  },

  // 右下角点击更多
  clickMove({ goods_id, item_sold_out, activity_from: activeFrom }) {
    daEventCenter.triggerNotice({
      daId: '2-3-3',
      extraData: {
        goods_id,
        item_sold_out,
        activeFrom
      },
    })
  },

  // 单个商品删除收藏
  deleteEventCenter({ goods_id, item_sold_out, activity_from }) {
    daEventCenter.triggerNotice({
      daId: '2-3-4',
      extraData: {
        goods_id,
        item_sold_out,
        activity_from,
      },
    })
  },
  
  clickBatchDeleteEventCenter() {
    daEventCenter.triggerNotice({
      daId: '1-10-5-11',
    })
  },

  // 确认批量商品删除收藏
  batchDeleteEventCenter({ activity_from, goods_list = [] }) {
    daEventCenter.triggerNotice({
      daId: '1-10-3-8',
      extraData: {
        activity_from,
        goods_list
      },
    })
  },

  selectItemEventCenter(selected) {
    daEventCenter.triggerNotice({
      daId: '1-10-3-16',
      extraData: {
        isSelected: selected
      }
    })
  },

  selectAllEventCenter() {
    daEventCenter.triggerNotice({
      daId: '1-10-4-2'
    })
  },

  clickAddBoardEventCenter() {
    daEventCenter.triggerNotice({
      daId: '1-10-4-1'
    })
  },

  clickCopyBoradEventCenter() {
    daEventCenter.triggerNotice({
      daId: '1-10-5-6'
    })
  },

  // 单个商品添加分组board
  itemAddBorad(goods_id) {
    daEventCenter.triggerNotice({
      daId: '1-10-4-6',
      extraData: { goods_id }
    })
  }
}

export default {  
  ...clickEvent,
  ...exposeEvent,
  resetExpose: (pageName) => {
    const daEventExpose = daEventCenter.getExposeInstance()
    daEventExpose?.resetAll(pageName)
  }
}

