<template>
  <div
    v-if="displayList?.length > 0"
    class="product-labels-carousel bff-product-labels-carousel"
  >
    <SwiperSlide
      class="product-labels-wraper"
      :autoplay="3000"
      :first-autoplay="1500"
      :transition-duration="300"
      :item-length="displayList?.length || 0"
      :touchable="false"
      :prevent-default="false"
      loop
      vertical
      intersection-observer
    >
      <SwiperSlideItem
        v-for="(item, i) in displayList"
        :key="i"
        :index="i"
        stop-propagation
      >
        <div class="product-labels-item">
          <span class="labels-item-icon">
            <Icon
              v-if="item.icon.startsWith('sui_icon')"
              :name="item.icon"
              color="#A86104"
              size=".32rem"
            />
            <img
              v-else
              :src="item.icon"
            />
          </span>
          <span class="labels-item-text">{{ item.labelLang }}</span>
        </div>
      </SwiperSlideItem>
    </SwiperSlide>
  </div>
</template>

<script name="BffProductLabelCarousel" setup>
import { computed, toRef } from 'vue'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {
      return {}
    }
  },
  language: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const goodsInfo = toRef(props, 'goodsInfo')

const displayList = computed(() => {
  const popularLabelList = goodsInfo.value?.pretreatInfo.popularLabelList || []

  const displayList = popularLabelList.filter(v => !!v.icon)
  
  return displayList
})

</script>

<style lang="less">
.product-labels-carousel,
.product-labels-wraper,
.product-labels-item {
  height: var(--label-height, 0.32rem);
}
.product-labels-carousel {
  --label-height: .3733rem;
  --icon-size: .32rem;
  --text-color: #A86104;
  --text-width: 3.466rem;
  --text-top: .5px;

  overflow: hidden;
  width: 100%;
  // border: 1px solid red;
  .product-labels-item {
    display: flex;
    align-items: center;
    color: var(--text-color);
    overflow: hidden;
    width: 100%;
  }
  .labels-item-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .labels-item-text {
    // border:1px solid red;
    // flex:1;
    // height: var(--label-height);
    // align-self: center;
    // padding-top: 1px;
    margin-top: var(--text-top);
    align-self: stretch;
    line-height: var(--label-height);
    width: var(--text-width, 4rem);
    .font-dpr(22px);
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    color: var(--text-color);
  }
}
</style>
