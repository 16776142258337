<template>
  <div class="reduce-price-label">
    {{ text }}
  </div>
</template>
  
<script>
/**
 * @file 降价信息组件
 */

import { defineComponent } from 'vue'
  
export default defineComponent({
  name: 'ReducePriceLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
})
</script>
  
<style lang="less" scoped>
.reduce-price-label {
    .text-overflow();
    .font-dpr(22px);
    line-height: 1.2;
    color: @sui_color_discount;
    text-align: left;
}
</style>
  
