import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '2-3' })
daEventCenter.addSubscriber({ modulecode: '1-10-4' })

export default {
  // 加车点击埋点
  addCartEventCenter(target) {
    daEventCenter.triggerNotice({
      daId: '2-3-5',
      target,
    })
  },
  
  // 找相识点击事件
  findSimilarEventCenter({ daEventId, data = { } }) {
    daEventCenter.triggerNotice({
      daId: '1-7-1-3',
      extraData: {
        daEventId,
        activeFrom: data.activeFrom,
        goods_id: data.goods_id,
        isOutOfStock: data.item_sold_out,
        findsimilar_method: data.findsimilar_method,
      },
    })
  },

  // 删除收藏商品确认弹框
  removeItemCenter({ goods_id, activity_from = 'wishlist', item_sold_out, delete_method = 'ClickMore' } = {}) {
    item_sold_out = Number(item_sold_out)
    daEventCenter.triggerNotice({
      daId: '1-10-4-9',
      extraData: {
        goods_id, 
        activity_from, 
        item_sold_out, 
        delete_method
      },
    })
  },

}
