export const isDef = (val) => (typeof val === 'string' ||  typeof val === 'number') && val !== undefined && val !== null && Number(val)

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export const preventDefault = (event, isStopPropagation) => {
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault()
  }
  if (isStopPropagation) {
    event.stopPropagation()
  }
}

export const useEventListener = (el, fn, options = {}, that = {}) => {
  if (!el || !fn) return
  const type = options.type || 'touchstart'
  document.addEventListener(type, (e) => {
    const touchTarget = el.contains(e.target)
    !touchTarget && fn && fn()
  })

  that.$once && that.$once('hook:beforeDestroy', () => document.removeEventListener(type, fn))
}
