<template>
  <div 
    v-if="showFalsh" 
    :style="itemStyle"
    class="new-flash-price"
  >
    <p v-if="data.flashTypeText"> 
      {{ data.flashTypeText }}
    </p>
    <div class="new-flash-price__price">
      {{ price }}
      <span 
        v-if="showRetail" 
        class="new-flash-price__retail-price"
      >
        {{ retailPrice.amountWithSymbol }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleRowFlashPrice', // 新闪购价格组件
  props: {
    data: {  // 闪购数据
      type: Object,
      default: () => ({})
    },
    retailPrice: { // 销售价格
      type: Object,
      default: () => ({})
    },
    showRetailPrice: {
      type: Boolean,
      default: true
    },
    itemStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    showFalsh() {
      // 1为普通闪购，2为新人闪购，3为特殊闪购
      return ['2', '3'].includes(`${this.data?.flash_type}`)
    },
    price () {
      return this.data?.price?.amountWithSymbol
    },
    showRetail () {
      return this.showRetailPrice && this.retailPrice?.amount && this.retailPrice?.amount > this.data?.price?.amount
    },
  }
}
</script>

<style lang="less">
.new-flash-price {
  margin-top: .2133rem;
  margin-bottom: .1067rem;
  padding: .1067rem;
  max-height: 1.04rem;
  width: 100%;
  background: #FFF6F3;
  color: #FA6338;

  p {
    margin-bottom: .0533rem;
    font-size: 12px;
    line-height: .32rem;
    font-weight: normal;
  }

  &__price {
    line-height: .4533rem;
    height: .4533rem;
    font-size: 14px;
    font-weight: bold;
  }

  &__retail-price {
    font-size: 12px;
    font-weight: normal;
    color: #999;
  }
}
</style>
