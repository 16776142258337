// 仅同类推荐用，从实时反馈拷过来的
const FEEDBACK_REC_INFO_KEY = 'FEEDBACK_REC_INFO'
const FEEDBACK_REC_DRAWER_KEY = 'FEEDBACK_REC_DRAWER'

export const getFeedbackRecInSession = () => {
  const feedbackRec = JSON.parse(sessionStorage.getItem(FEEDBACK_REC_INFO_KEY)) || {}

  return feedbackRec
}

/**
 * @param {Object} feedbackRec 
{
  goodsId: "",
  ymal: '', // ymal曝光的前20个goodsId
  similar: '', // similar曝光的前20个goodsId
  addWishStatus: 0 || 1,
  addBagStatus: 0 || 1
}
 */
export const setFeedbackRecInSession = (feedbackRec = {}) => {
  sessionStorage.setItem(FEEDBACK_REC_INFO_KEY, JSON.stringify(feedbackRec))
}

export const getFeedbackRecDrawerInSession = () => {
  const config = sessionStorage.getItem(FEEDBACK_REC_DRAWER_KEY)
  
  return config ? JSON.parse(config) : {}
}

/**
 * @param {Object} params 
{
  visible: true || false,是否展示推荐半屏弹窗
}
 */
export const setFeedbackRecDrawerInSession = (params = {}) => {
  sessionStorage.setItem(FEEDBACK_REC_DRAWER_KEY, JSON.stringify({
    ...getFeedbackRecDrawerInSession(),
    ...params
  }))
}

export const setFeedbackRecInfo = ({ productListInstance, path }) => {
  const productDetailRegx = /(?:\/shell)?\/.+-p-(\d+)(-cat-(\d+))?\.html/ // 商详url正则
  if (window.appRouteExtData.direction == -1 && (productDetailRegx.test(path) || path === 'page_goods_detail')) {
    // 是否售罄
    const { goodsId  } = getFeedbackRecInSession() || {}
    let item = productListInstance?.$refs?.[`WishProductItem_${goodsId}`]?.item || ''
    let status = item && (Number(item?.is_on_sale) === 0 || Number(item?.stock) === 0)
    productListInstance?.showWishFeedbackRecPopup && productListInstance?.showWishFeedbackRecPopup()
    productListInstance?.showSimilarRecPopup && productListInstance?.showSimilarRecPopup(status)
  } 
}


// 需要使用骨架屏loading的页面来源
const useSkeletonLoadingPageFrom = ['page_category']

/**
 * @description 是否使用骨架屏loading
 * @param {String} pageFrom 页面来源
 * @returns {Boolean} 是否使用骨架屏loading
 */
export const isUseSkeletonLoading = (pageFrom) => {
  if (!isShein()) return false
  return useSkeletonLoadingPageFrom.includes(pageFrom)
}


/**
 * @description 是否是重置请求类型
 * @param {AbortController} abortionInstance 中断请求实例
 */
export const isResetRequestType = (abortionInstance = {}) => {
  const signal = abortionInstance?.signal
  return signal?.reason === 'picTopNav2QueryAbortFetch'
}

/**
 * @description 是否是shein
 * @returns {Boolean} 是否是shein
 */
export const isShein = () => {
  return typeof window !== 'undefined' && window.gbCommonInfo?.WEB_CLIENT === 'shein'
}

export const TRIGGER_EVENT_MAP = {
  'AddCarFailFavSuccess': '1',
  'AddCarFailFavFail': '2',
  'AddCarSuccessFavSuccess': '3',
  'AddCarSuccessFavFail': '4',
  'NotJudged': '5',
}

/**
 * @description abt解析给到推荐的ruleId
 * @param {*} listAbtResult abtList
 * @param {*} triggerEvent 触发事件
 * @returns 
 */
export const feedbackAbtToRuleId = (listAbtResult, triggerEvent) => {
  const ruleId = {}
  const index = TRIGGER_EVENT_MAP[triggerEvent]
  const abt = listAbtResult?.RealtimeFeedback?.p || {}
  
  Object.keys(abt).forEach((key) => { 
    const arr = abt[key].split('&')
    if (arr.length <= 1) {
      ruleId[key] = abt[key]
    } else {
      arr.forEach(str => {
        const [k, v] = str.split('=')
        if (k === index) {
          ruleId[key] = v
        }
      })
    }
  })

  return ruleId
}
