export default class AllSelect {
  constructor({ limitNum = 100 } = {}) {
    this.selectMap = new Map()
    this.limitNum = limitNum // 选中限制数量
  }

  get() {
    return Object.fromEntries(this.selectMap.entries())
  }

  getGoodIds() {
    const goodsIds = []
    for (let goods_id of this.selectMap.keys()) {
      goodsIds.push(goods_id)
    }
    return goodsIds
  }

  // 删除收藏商品时使用
  getWishlistIds() {
    const wishlistIds = []
    for (let item of this.selectMap.values()) {
      item.wishlistId && wishlistIds.push(item.wishlistId)
    }
    return wishlistIds
  }

  getItems() {
    const items = []
    for (let item of this.selectMap.values()) {
      items.push(item)
    }
    return items
  }

  set(item) {
    if (this.getGoodIds().length >= this.limitNum) { // 最多设置100个
      return 
    }
    this.selectMap.set(item.goods_id, item)
  }

  delete(item) {
    this.selectMap.delete(item.goods_id)
  }

  clear() {
    this.selectMap.clear()
  }

  verify(products) {
    const selectGoods = this.getGoodIds()
    selectGoods.forEach(id => {
      const selectId = products.some(item => item.goods_id == id)
      if (!selectId) {
        this.selectMap.delete(id)
      }
    })
  }

  // 检查商品是否还在
  updateSelectGoods(products = []) {
    this.verify(products)
    return this.getGoodIds()
  }
}
