<template>
  <i
    class="suiiconfont sui_icon_more_20px wish-product-more-btn"
    :style="iconStyle"
  ></i>
</template>

<script>
/**
 * @file 更多按钮组件
 */

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MoreInfoBtn',
  setup () {
    return {
      iconStyle: {
        'font-size': '12px',
        color: '#767676',
        transform: 'scale(1.333333)',
        display: 'inline-block',
      },
    }
  },
})
</script>
