import getWishItemConfig from '../itemConfig'

export const getItemV3PriceCfg = ({ isPaid, showEstimatedPriceAbt }) => ({
  showDiscountLabelAfterPrice: false, // 价格后展示折扣标签
  showEstimatedPriceOnSale: showEstimatedPriceAbt, // 到手价-占用售价坑位
  showEstimatedPriceReachThreshold: false, // 老到手价样式
  showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
  showSheinClubDiscountValueAfterPrice: false, // 不展示小号付费会员折扣标签
  showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
  showSheinClubPriceOnSale: !!isPaid, // 是付费会员，就将价格展示为付费会员价格
  showSheinClubNewLabel: !!isPaid, // 展示新的付费会员文字标签
  showNewFlashNewLabel: true, // 展示新的新型闪购文字标签
  hideSpecialDeDiscountLabel: true,
  commonParams: {
    pageName: 'page_collection'
  }
})

export const getUserWishCfg = (originConfig = {}, abtData = {}, { isPaid, sheinClubType }, pageType) => {
  const showNewSheinClubTag = `${sheinClubType}` === '2'
  const config = getWishItemConfig(originConfig, abtData)

  const { EstimatedPrice, listwithCoupon } = abtData
  const showEstimatedPriceAbt = ['Have_Threshold', 'Non_Threshold'].includes(EstimatedPrice?.p?.E_Price_Cal) && 
    !(listwithCoupon?.p?.listwithCoupon === 'off' && pageType === 'personal') // 到手价abt
  const innerCfg = {
    showLeftStock: true,
    showSaleAttr: true,
    showItemV3: true,
    showSalesLabel: true,
    showSheinClubDiscountValue: `${sheinClubType}` === '1', // 老的付费会员标识
    showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
    showSheinClubPriceOnSale: !!(showNewSheinClubTag && isPaid),
    showNewStyleEstimated: listwithCoupon?.p?.listwithCoupon === 'withCoupon', //到手价新样式
    // standardPrice, // 老商卡合规价
  }
  if (innerCfg.showItemV3) {
    Object.assign(innerCfg, { showLeftStock: false, showCardLabelsContainer: false }, getItemV3PriceCfg({ isPaid, showEstimatedPriceAbt }))
  }

  return {
    ...config,
    ...innerCfg,
  }
}

export const getWishGroupCfg = (originConfig = {}, abtData = {}, { sheinClubType, isPaid }, pageType) => {
  const config = getWishItemConfig(originConfig, abtData)

  const { EstimatedPrice, listwithCoupon } = abtData

  const showOldSheinClubTag = `${sheinClubType}` === '1'
  const showNewSheinClubTag = `${sheinClubType}` === '2'

  const innerCfg = {
    showSheinClubDiscountValue: showOldSheinClubTag,
    showSheinClubDiscountValueAfterPrice: showNewSheinClubTag,
    showSheinClubPriceOnSale: !!(showNewSheinClubTag && isPaid),
    showLeftStock: true,
    showSaleAttr: true,
    showItemV3: true,
    showSalesLabel: true,
    showNewStyleEstimated: listwithCoupon?.p?.listwithCoupon === 'withCoupon', //到手价新样式
    // standardPrice, // 老商卡合规价
  }
  const showEstimatedPriceAbt = ['Have_Threshold', 'Non_Threshold'].includes(EstimatedPrice?.p?.E_Price_Cal) && 
    !(listwithCoupon?.p?.listwithCoupon === 'off' && pageType === 'personal') // 到手价abt
  if (innerCfg.showItemV3) {
    Object.assign(innerCfg, { showLeftStock: false, showCardLabelsContainer: false }, getItemV3PriceCfg({ isPaid, showEstimatedPriceAbt }))
  }

  return {
    ...config,
    ...innerCfg,
  }
}
