class UseTouch {
  constructor() {
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.currentY = 0
    this.deltaX = 0
    this.direction = null
  }

  reset() {
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.currentY = 0
    this.deltaX = 0
    this.direction = null
  }

  start(e) {
    this.reset()
    const { pageX, pageY } = this.getTouches(e)
    this.startX = pageX
    this.startY = pageY
  }

  move(e) {
    const { pageX, pageY } = this.getTouches(e)
    this.currentX = pageX
    this.currentY = pageY
    this.deltaX = this.currentX - this.startX

    const LOCK_DIRECTION_DISTANCE = 10 // 锁定方向距离,  在都小于10距离时，判断谁的值大来进行设置方向
    const offsetX = Math.abs(this.deltaX)
    const offsetY = Math.abs(this.currentY - this.startY)
    if (
      !this.direction ||
      (offsetX < LOCK_DIRECTION_DISTANCE && offsetY < LOCK_DIRECTION_DISTANCE)
    ) {
      this.direction = this.getDirection(offsetX, offsetY)
    }
  }

  getTouches = (e) => {
    return e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])
  }

  getDirection(x, y) {
    if (x > y) {
      return 'horizontal' // 水平方向
    }
    if (y > x) {
      return 'vertical'
    }
    return ''
  }

  isHorizontal() {
    return this.direction === 'horizontal'
  }
}
export default UseTouch
