
import StockLeft from './StockLeft.vue'

const getStockText = ({ item }) => {
  return item.pretreatInfo?.stockLeft || ''
}

export {
  StockLeft,
  getStockText,
}
