
import WishNum from './WishNum.vue'

const getItemCollection = ({ showCollectNum, wishVal }) => {
  if (!showCollectNum) return 0  // 控制是否展示收藏数
  let count = Number(wishVal)
  if (!count) {
    count = 1 // 数据兜底逻辑
  }
  if (count > 999) {
    count = Math.floor((count / 1000) * 10) / 10 + 'k'
  }
  return count
}

export {
  WishNum,
  getItemCollection,
}
