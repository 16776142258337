// 收藏页面的实时反馈mixin:
import { getFeedbackRecInSession, setFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils.js'

export default {
  methods: {
    getProductListItemInstance(goodsId) {
      const productListItemRef = this.$refs?.[`WishProductItem_${goodsId}`]
      return productListItemRef?.[0] || productListItemRef
    },
    // 通过商品的 goodsId 找到对应商品在 productList 列表中的 index
    // 注意: 入参的 goodsId 有可能是色块的 goodsId
    getCurItemIndex(goodsId) {
      return this.productList.findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
    },
    // 显示实时反馈弹窗
    async showWishFeedbackRecPopup() {
      const { goodsId, addBagStatus, addWishStatus } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex < 0) return
      // 再通过 index 拿到对应的 productListItem 实例
      const listItemRef = this.getProductListItemInstance(goodsId)
      if (!listItemRef) return
      listItemRef.recommendPopupVisible = true
      let recommendPopupRef = listItemRef?.$refs?.recommendPopup
      if (recommendPopupRef) {
        recommendPopupRef.setFeedbackRecInfo({ goodsId, addBagStatus, addWishStatus })
        return
      }

      // 等待 recommendPopup 渲染出来
      await this.$nextTick()
      recommendPopupRef = listItemRef?.$refs?.recommendPopup
      recommendPopupRef && recommendPopupRef.setFeedbackRecInfo({ goodsId, addBagStatus, addWishStatus })
    },
    // 隐藏实时反馈弹窗
    async hideWishFeedbackRecPopup() {
      const { goodsId } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex >= 0) {
        // 再通过 index 拿到对应的 productListItem 实例
        const listItemRef = this.getProductListItemInstance(goodsId)
        if (listItemRef) {
          listItemRef.recommendPopupVisible = false
        }
      }
    },
    handleWishFeedbackRecInfo ({ active, clickDetails, goodsId, addBagStatus, addWishStatus, isRecommend }) {
      if (!isRecommend) return

      if (!active) {
        // clickDetails 点击details跳去商详，会关闭弹窗。此种情况，不取弹窗里面的操作状态（加车和收藏）
        if (!clickDetails) {
          setFeedbackRecInSession({ goodsId, addBagStatus, addWishStatus })
          this.showWishFeedbackRecPopup()
        }
      } else {
        this.hideWishFeedbackRecPopup()
      }
    }
  }
}
