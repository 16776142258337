var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-pic-wrap__img",on:{"touchstart":_vm.touchstart,"touchmove":_vm.touchmove,"touchend":_vm.touchend}},[(_vm.lcp)?_c('picture',[_c('source',{attrs:{"srcset":_vm.imgUrl.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp'),"type":"image/webp"}}),_vm._v(" "),_c('img',{staticClass:"line-pic-wrap__img-main",attrs:{"src":_vm.imgUrl.replace(/^https?\:/, ''),"alt":""}})]):_c('img',{staticClass:"line-pic-wrap__img-main lazyload",attrs:{"src":_vm.constantData.LAZY_IMG_SOLID_COLOR,"data-src":_vm.imgUrl,"alt":""}}),_vm._v(" "),(_vm.locateLabelSet.size)?_c('item-locate-labels',{attrs:{"multi-column":false,"locate-labels":_vm.locateLabels,"lazy-img":_vm.constantData.LAZY_IMG_PIXEL}}):_vm._e(),_vm._v(" "),(!_vm.locateLabelSet.has('UPPER_LEFT_LABEL'))?_c('ProductItemImgTopLeft',{attrs:{"pretreat-info":_vm.pretreatInfo,"config":_vm.config}}):_vm._e(),_vm._v(" "),(!_vm.locateLabelSet.has('LOWER_LEFT_LABEL'))?_c('ProductItemImgBottomLeft',{attrs:{"pretreat-info":_vm.pretreatInfo,"config":{
      showSeriesBrand: _vm.config.showSeriesBrand
    },"has-bottom-badge":_vm.hasBottomBadge,"constant-data":{ LAZY_IMG_PIXEL: _vm.constantData.LAZY_IMG_PIXEL }}}):_vm._e(),_vm._v(" "),(!_vm.locateLabelSet.has('UPPER_RIGHT_LABEL'))?_c('ProdcutItemImgTopRight',{attrs:{"pretreat-info":_vm.pretreatInfo,"cur-data":{
      video_url: _vm.videoUrl
    },"config":{
      showSeriesBrand: _vm.config.showSeriesBrand
    },"constant-data":{ LAZY_IMG_PIXEL: _vm.constantData.LAZY_IMG_PIXEL },"promo-label-src":_vm.promoLabelSrc}}):_vm._e(),_vm._v(" "),(_vm.saleout.show)?_c('div',{staticClass:"line-pic-wrap__imgAbsolute sold-out"},[_c('i',{staticClass:"iconfont",class:_vm.constantData.IS_RW ? 'icon-category-' : 'icon-SOLDOUT'}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.saleout.message))])]):_c('div',{staticClass:"line-pic-wrap__imgAbsolute",staticStyle:{"bottom":"0"}},[(_vm.showStockLeft)?_c('div',{staticClass:"repertory-num"},[_vm._v("\n      "+_vm._s(_vm.stockLeft)+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.beltLabel && _vm.pretreatInfo.followLabelVisible)?_c('div',{staticClass:"line-pic-wrap__bottomlabel"},[_c('ImgBottomLabel',{attrs:{"type":_vm.bottomLabelInfo.type,"center":_vm.bottomLabelInfo.center,"label-text":_vm.bottomLabelInfo.text,"label-icon":_vm.bottomLabelInfo.icon,"line-clamp":_vm.bottomLabelInfo.lineClamp}})],1):_vm._e(),_vm._v(" "),(_vm.beltLabel)?_c('div',{staticClass:"line-pic-wrap__bottom"},[_c('ImgBottomBeltLabel',{attrs:{"belt-label":_vm.beltLabel}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }