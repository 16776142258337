<template>
  <div 
    class="line-pic-wrap__img"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
  > 
    <!-- 主图 -->
    <picture v-if="lcp">
      <source
        :srcset="imgUrl.replace(/^https?\:/, '').replace(/\b(\.jpg|\.png)\b/, '.webp')"
        type="image/webp"
      />
      <img
        class="line-pic-wrap__img-main"
        :src="imgUrl.replace(/^https?\:/, '')"
        alt=""
      />
    </picture>
    <img
      v-else
      class="line-pic-wrap__img-main lazyload"
      :src="constantData.LAZY_IMG_SOLID_COLOR"
      :data-src="imgUrl"
      alt=""
    />

    <item-locate-labels
      v-if="locateLabelSet.size"
      :multi-column="false"
      :locate-labels="locateLabels"
      :lazy-img="constantData.LAZY_IMG_PIXEL"
    />

    <!-- 图片左上角 -->
    <ProductItemImgTopLeft
      v-if="!locateLabelSet.has('UPPER_LEFT_LABEL')"
      :pretreat-info="pretreatInfo"
      :config="config"
    />

    <!-- 图片左下角角标 -->
    <ProductItemImgBottomLeft
      v-if="!locateLabelSet.has('LOWER_LEFT_LABEL')"
      :pretreat-info="pretreatInfo"
      :config="{
        showSeriesBrand: config.showSeriesBrand
      }"
      :has-bottom-badge="hasBottomBadge"
      :constant-data="{ LAZY_IMG_PIXEL: constantData.LAZY_IMG_PIXEL }"
    />

    <!-- 图片右上角 -->
    <ProdcutItemImgTopRight
      v-if="!locateLabelSet.has('UPPER_RIGHT_LABEL')"
      :pretreat-info="pretreatInfo"
      :cur-data="{
        video_url: videoUrl
      }"
      :config="{
        showSeriesBrand: config.showSeriesBrand
      }"
      :constant-data="{ LAZY_IMG_PIXEL: constantData.LAZY_IMG_PIXEL }"
      :promo-label-src="promoLabelSrc"
    />

    <!-- 售空 -->
    <div 
      v-if="saleout.show" 
      class="line-pic-wrap__imgAbsolute sold-out"
    >
      <i
        class="iconfont"
        :class="constantData.IS_RW ? 'icon-category-' : 'icon-SOLDOUT'"
      ></i>
      <span>{{ saleout.message }}</span>
    </div>

    <div 
      v-else 
      class="line-pic-wrap__imgAbsolute"
      style="bottom:0;"
    >
      <!-- 库存数量 only xxx left -->
      <div
        v-if="showStockLeft"
        class="repertory-num"
      >
        {{ stockLeft }}
      </div>

      <div
        v-if="!beltLabel && pretreatInfo.followLabelVisible"
        class="line-pic-wrap__bottomlabel"
      >
        <ImgBottomLabel
          :type="bottomLabelInfo.type"
          :center="bottomLabelInfo.center"
          :label-text="bottomLabelInfo.text"
          :label-icon="bottomLabelInfo.icon"
          :line-clamp="bottomLabelInfo.lineClamp"
        />
      </div>
      
      <!-- 腰带 -->
      <div 
        v-if="beltLabel" 
        class="line-pic-wrap__bottom"
      >
        <ImgBottomBeltLabel 
          :belt-label="beltLabel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductItemImgTopLeft from 'public/src/pages/components/product/item_v2/components/ProductItemImgTopLeft.vue'
import ProductItemImgBottomLeft from 'public/src/pages/components/product/item_v2/components/ProductItemImgBottomLeft.vue'
import ProdcutItemImgTopRight from 'public/src/pages/components/product/item_v2/components/ProductItemImgTopRight'
import ItemLocateLabels from 'public/src/pages/components/product/item_v2/components/ItemLocateLabels.vue'
import { transformImg } from '@shein/common-function'

export default {
  components: {
    ProductItemImgTopLeft,
    ProductItemImgBottomLeft,
    ProdcutItemImgTopRight,
    ItemLocateLabels,
    ImgBottomLabel: () => import('public/src/pages/components/product/item_v2/components/UI/ImgBottomLabel.vue'),
    ImgBottomBeltLabel: () => import('public/src/pages/components/product/item_v2/components/ImgBottomBeltLabel.vue')
  },
  props: {
    constantData: {
      type: Object,
      default: () => ({})
    },
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    locateLabels: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    imgUrl: {
      type: String,
      default: ''
    },
    videoUrl: {
      type: String,
      default: ''
    },
    promoLabelSrc: {
      type: String,
      default: ''
    },
    lcp: Boolean,
  },
  computed: {
    // 图片底部：大码|romwe系列品牌|romwe独创款
    hasBottomBadge () {
      return !!(this.pretreatInfo?.plusSize || this.pretreatInfo?.seriesOrBrandLogoBottom || this.pretreatInfo?.romweoriginals)
    },
    // 展示低库存信息
    showStockLeft () {
      return this.stockLeft && this.config.showLeftStock 
    },
    // 低库存
    stockLeft () {
      return this.pretreatInfo?.stockLeft
    },
    saleout() {
      return this.pretreatInfo?.saleout || {}
    },
    beltLabel() {
      return this.config.showBeltLabel && this.pretreatInfo?.['mobileBroadwiseView']?.beltLabel
    },
    locateLabelSet () {
      return new Set(Object.keys(this.locateLabels || {}).filter(key => Object.keys(this.locateLabels[key] || {}).length))
    },
    bottomLabelInfo () {
      return {
        type: ['orange', '12PX'],
        icon: 'sui_icon_best_deal_16px',
        text: this.language.SHEIN_KEY_PWA_26113,
        lineClamp: 1,
        center: true,
      }
    },
  },
  methods: {
    touchstart () {
      this.longClickTimer = setTimeout(() => {
        this.emitLongClick = true
        this.$emit('longClick')
      }, this.config.longClickTime || 1000)
    },
    touchmove () {
      clearTimeout(this.longClickTimer)
      this.longClickTimer = null
    },
    touchend (e) {
      if (this.emitLongClick) {
        e.preventDefault()
        this.emitLongClick = false
      }
      clearTimeout(this.longClickTimer)
      return false
    },
    transformImg,
  }
}
</script>

<style lang="less">
.line-pic-wrap {
  &__img {
    position: relative;
    width: 2.56rem;
    height: 3.41rem;
    .line-pic-wrap__img-main {
      width: 2.56rem;
      height: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
    .sold-out {
      top: 0;
      left: 0;
      width: 2.56rem;
      height: 3.41rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      background: rgba(65, 63, 63, 0.7);
      font-size: 0.32rem;
      font-weight: 550;
      i {
        font-size: 0.85rem;
      }
      span {
        transform: translateY(-10px);
      }
    }
    // 库存数量
    .repertory-num {
      width: 2.56rem;
      height: 0.48rem;
      line-height: 0.48rem;
      text-align: center;
      font-size: 12px;
      background: rgba(65, 63, 63, 0.8);
    }

    // @tips: public/src/pages/components/product/item_v2/components/ItemLocateLabels.vue
    .product-item-upperleft.product-locatelabels-text {
      transform: scale(0.8);
      transform-origin: left;
    }
  }

  &__bottomlabel {
    width: 2.56rem;
  }

  &__imgAbsolute {
    position: absolute;
    color: #fff;
    z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
  }

  &__bottom {
    .product-item__imgbottom-belt {
       .imgbottom-belt__text {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
       }
    }
  }
}
</style>
