
import ReducePriceLabel from './ReducePriceLabel.vue'

const getReducePriceLabel = ({ item }) => {
  return item.pretreatInfo?.reducePrice?.label || ''
}

export {
  ReducePriceLabel,
  getReducePriceLabel,
}
