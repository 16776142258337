<template>
  <div>
    <!-- 普通价格、新闪购价格 -->
    <section
      v-if="!vipPrice"
      class="line-pic-wrap__price"
      :style="priceStyle"
      tabindex="0"
      :aria-label="salePrice.amountWithSymbol"
    >
      <!-- 付费会员价格 -->
      <div 
        v-if="showSheinClubPrice"
        class="line-pic-wrap__price-sheinclub"
      >
        {{ sheinClubPrice.amountWithSymbol }}
      </div>
      <!-- 新闪购价格、 和普通价格互斥 -->
      <FlashPrice
        v-else-if="newFlashPromotion"
        :data="newFlashPromotion"
        :retail-price="retailPrice"
        :show-retail-price="!config.hideRetailPrice"
      />
      <!-- 普通价格 -->
      <template v-else>
        <i 
          v-if="iconList.length" 
          class="suiiconfont line-pic-wrap__content-flashsale" 
          :class="iconList"
        > </i>

        <div :style="salePriceStyle">
          {{ salePrice.amountWithSymbol }}
        </div>
        <span
          v-if="inexpensivePrice && !config.hideRetailPrice"
          tabindex="0"
          role="text"
          :aria-label="retailPrice.amountWithSymbol"
          class="line-pic-wrap__price-retail"
        >
          {{ retailPrice.amountWithSymbol }}
        </span>
      </template>

      <!-- 折扣标签 -->
      <DiscountLabel 
        v-if="config.showSheinClubDiscountValue && discountInfo"
        :type="config.showNewDiscountLabel ? 'newDiscount' : 'discount'"
        :discount-info="discountInfo"
        class="line-pic-wrap__discount-label"
      />
      <ProductItemSheinClubDiscount
        v-if="showSheinClubPrice && sheinClubDiscountValue"
        :discount-value="sheinClubDiscountValue"
        :public-cdn="constantData.PUBLIC_CDN"
        class="line-pic-wrap__sheinclub-discount"
        type="small"
      />
    </section>

    <!-- 付费会员 -->
    <ProductItemSheinClubDiscount
      v-if="config.showSheinClubDiscountValue && sheinClubDiscountValue"
      :discount-value="sheinClubDiscountValue"
      :public-cdn="constantData.PUBLIC_CDN"
    />

    <!-- 会员价格 -->
    <template v-if="vipPrice">
      <section
        v-if="!constantData.IS_RW"
        class="line-pic-wrap__vip"
        tabindex="0"
        role="text"
        :aria-label="vipPrice"
      >
        {{ vipPrice }}
        <em :class="[constantData.GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag', 'vip-exclusive-tag_color-black']">
        </em>
        <span
          tabindex="0"
          role="text"
          :aria-label="retailPrice.amountWithSymbol"
        >
          {{ retailPrice.amountWithSymbol }}
        </span>
      </section>
      <template v-else>
        <RomweVipPrice
          style="margin-top: 2px"
          :price="vipPrice"
          :retail-price="retailPrice"
        />
      </template>
    </template>
  </div>
</template>

<script>
import RomweVipPrice from 'public/src/pages/components/product/VipPrice.vue'
import FlashPrice from './singleRowFlashPrice.vue'
import ProductItemSheinClubDiscount from 'public/src/pages/components/product/item_v2/components/ProductItemSheinClubDiscount.vue'
import DiscountLabel from 'public/src/pages/components/product/item_v2/components/UI/DiscountLabel.vue'

export default {
  components: {
    RomweVipPrice,
    FlashPrice,
    ProductItemSheinClubDiscount,
    DiscountLabel
  },
  props: {
    constantData: {
      type: Object,
      default: () => ({})
    },
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    exclusivePromotionPrice: {
      type: Object,
      default: () => ({})
    },
    retailPrice: {
      type: Object,
      default: () => ({})
    },
    salePrice: {
      type: Object,
      default: () => ({})
    },
    promotionModule: {
      type: Object,
      default: () => ({})
    },
    sheinClubPromotionInfo: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    // 会员价格
    vipPrice () {
      return this.exclusivePromotionPrice?.amountWithSymbol
    },
    // 商品价格和促销价格是否相同
    inexpensivePrice () {
      return this.retailPrice?.amountWithSymbol !== this.salePrice?.amountWithSymbol
    },
    priceStyle() {
      if (!this.inexpensivePrice) return {}
      return {
        color: this.constantData?.IS_RW ? `#FC4070` : `#fa6338`,
      }
    },
    salePriceStyle () {
      if (this.pretreatInfo.followLabelVisible && !this.showSheinClubPrice && !this.sheinClubDiscountValue) {
        return {
          color: '#fa6338',
          display: 'inline-block',
        }
      }
      return {
        display: 'inline-block',
      }
    },
    newFlashPromotion() {
      return this.pretreatInfo?.newFlashPromotion || null
    },
    iconList () {
      let icon = []
      this.promotionModule?.rawModule?.forEach(i => {
        if (!i.icon) return

        if (i.typeId !== 8) {
          if (i.typeId == 10) {
            if (i.flash_type == 1) icon.push(i.icon) // 常规闪电图标
            return
          }
          icon.push(i.icon)
        }
      })
      return icon
    },
    // 付费会员
    sheinClubDiscountValue () {
      return this.sheinClubPromotionInfo?.discountValue || ''
    },
    discountInfo () {
      return this.config.showDiscountLabelAfterPrice && this.pretreatInfo?.discountInfo
    },
    showSheinClubPrice() {
      return this.config.showSheinClubPriceOnSale && this.sheinClubPrice.amountWithSymbol
    },
    sheinClubPrice () {
      return this.sheinClubPromotionInfo?.price || {}
    },
  }
}
</script>

<style lang="less">
.line-pic-wrap {
  &__price {
    .flexbox();
    margin-top: 0.1rem;
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    color: #222;
    i {
      margin-right: 0.0533rem;
      &.sui_icon_flashsale_15px {
        .font-dpr(30px);
        color: @sui_color_flash;
      }
      &.sui_icon_time_15px {
        .font-dpr(30px);
        color: @sui_color_discount;
      }
    }
    span {
      font-size: 12px;
      font-weight: 400;
      color: #999;
      text-decoration: line-through;
    }
  }

  &__price-sheinclub {
    color: @sui_color_club_rosegold_dark1;
  }
  
  &__price-retail {
    margin-left: 0.0533rem;
  }
  &__discount-label {
    margin-left: 0.1067rem;
  }
  &__sheinclub-discount {
    margin: 0 0 0 0.1067rem;
  }

  // 会员价格
  &__vip {
    margin-top: 0.1rem;
    font-size: 0.32rem;
    font-weight: 550;
    color: #af945c;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #767676;
      text-decoration: line-through;
    }
  }
}
</style>
<style lang="less">
@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';

.vip-exclusive-tag,
.vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 3.23rem .77rem;
  width: .85rem;
  height: .27rem;
  &_color-yellow {
    background-position: -1.45rem -.25rem;
  }
  &_color-black {
    background-position: -.48rem -.25rem;
  }
}
.vip-exclusive-tag {
  background-image: url(@s3_icon);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_icon_ar);
  vertical-align: -0.026667rem;
}
</style>
